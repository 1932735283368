/* PlayerInfo.css - Versión Final */

.player-info {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  color: var(--text-color, #333); /* Usa solo el color que recibe */
}

.player-header {
  margin-bottom: 20px;
  text-align: center;
}

.player-title {
  font-size: 1.4em;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: inherit; /* Hereda el color del contenedor */
  position: relative;
  display: inline-block;
  padding-bottom: 8px;
}

.player-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: currentColor; /* Usa el mismo color del texto */
  opacity: 0.5;
  border-radius: 3px;
}

.player-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.6);
}

.info-icon {
  font-size: 1.3em;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  flex-shrink: 0;
  color: inherit; /* Hereda el color del texto */
}

.info-content {
  flex: 1;
  min-width: 0;
}

.info-label {
  font-size: 0.8em;
  opacity: 0.8;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: inherit; /* Hereda el color */
}

.info-value {
  font-size: 1.1em;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit; /* Hereda el color */
}

/* Responsive */
@media (max-width: 768px) {
  .player-info {
    padding: 16px;
  }
  
  .info-item {
    padding: 10px 14px;
  }
}

@media (max-width: 480px) {
  .player-info {
    padding: 14px;
  }
  
  .player-title {
    font-size: 1.2em;
  }
}