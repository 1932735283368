/* src/components/GameConfig/MemoryMatchFields.css */
.memory-match-fields {
  background: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.section {
  margin-bottom: 2.5rem;
}

.section:last-child {
  margin-bottom: 0;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3436;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.section-title::before {
  content: '';
  display: block;
  width: 4px;
  height: 1.25rem;
  background-color: #6C5CE7;
  border-radius: 2px;
}

.section-subtitle {
  font-size: 0.85rem;
  font-weight: 400;
  color: #6c757d;
  margin-left: 0.5rem;
}

.image-upload-container,
.single-image-upload {
  margin-bottom: 1.5rem;
}

.upload-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #6C5CE7;
  color: white;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
}

.upload-button:hover {
  background-color: #5a4bd1;
  transform: translateY(-1px);
}

.upload-button:disabled {
  background-color: #adb5bd;
  cursor: not-allowed;
  transform: none;
}

.hidden-input {
  display: none;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.image-card {
  position: relative;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.image-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: rgba(214, 48, 49, 0.9);
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
}

.image-card:hover .delete-button {
  opacity: 1;
}

.delete-button:hover {
  background-color: #bb2d3b;
}

.delete-button:disabled {
  background-color: #adb5bd;
  cursor: not-allowed;
}

.image-preview-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 300px;
}

.image-preview-container img {
  max-height: 200px;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.setting-group {
  margin-bottom: 1.5rem;
  background-color: #f8f9fa;
  padding: 1.25rem;
  border-radius: 8px;
}

.setting-group label {
  display: block;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #2d3436;
}

.setting-input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.setting-input:focus {
  outline: none;
  border-color: #6C5CE7;
}

.setting-description {
  font-size: 0.85rem;
  color: #6c757d;
  margin-top: 0.25rem;
}

@media (max-width: 768px) {
  .memory-match-fields {
    padding: 1.5rem;
  }
  
  .image-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

.image-preview-container {
  position: relative;
  margin-bottom: 1rem;
}

.image-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.replace-button {
  background: #6C5CE7;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.replace-button:hover {
  background: #5a4bd1;
}

.replace-button input[type="file"] {
  display: none;
}