/* Leaderboard.css - Versión Integrada */

.leaderboard-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.05);
  overflow: hidden;
  position: relative;
  color: inherit;
}

.leaderboard-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, 
    transparent 0%, 
    var(--accent-color) 50%, 
    transparent 100%);
}

.leaderboard-header {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.leaderboard-title {
  font-size: 1.3em;
  font-weight: 600;
  margin: 0;
  color: inherit;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.leaderboard-title::before {
  content: '🏆';
  font-size: 1.2em;
  opacity: 0.8;
}

.leaderboard-scroll {
  flex: 1;
  overflow-y: auto;
  padding: 0 20px 20px;
}

.leaderboard-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.leaderboard-item {
  display: grid;
  grid-template-columns: 30px 1fr auto;
  align-items: center;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  transition: all 0.2s ease;
  position: relative;
}

.leaderboard-item::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.05);
}

.leaderboard-item:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateX(4px);
}

.item-rank {
  font-size: 1em;
  font-weight: 600;
  color: var(--accent-color);
  opacity: 0.8;
  text-align: center;
}

.item-content {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
}

.item-name {
  font-weight: 500;
  font-size: 0.95em;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-time {
  font-family: 'Courier New', monospace;
  font-size: 0.85em;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.5px;
}

/* Top 3 destacados usando el color de acento */
.leaderboard-item:nth-child(-n+3) {
  background: linear-gradient(
    90deg,
    var(--accent-color, rgba(93, 61, 253, 0.1)) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
}

.leaderboard-item:nth-child(1) .item-rank {
  color: var(--accent-color);
  opacity: 1;
  font-weight: 700;
}

.leaderboard-item:nth-child(2) .item-rank {
  color: var(--accent-color);
  opacity: 0.8;
}

.leaderboard-item:nth-child(3) .item-rank {
  color: var(--accent-color);
  opacity: 0.6;
}

/* Barra de scroll que usa el color de acento */
.leaderboard-scroll::-webkit-scrollbar {
  width: 6px;
}

.leaderboard-scroll::-webkit-scrollbar-thumb {
  background: var(--accent-color);
  border-radius: 3px;
  opacity: 0.4;
}

.leaderboard-scroll::-webkit-scrollbar-thumb:hover {
  opacity: 0.6;
}

/* Estado vacío */
.leaderboard-empty {
  padding: 40px 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  font-style: italic;
}
