/* src/components/common/Podium/Podium.css */
:root {
  --gold: linear-gradient(145deg, #ffd700, #ffcc00);
  --silver: linear-gradient(145deg, #e6e6e6, #c0c0c0);
  --bronze: linear-gradient(145deg, #cd7f32, #b87333);
  --neon-glow: 0 0 15px rgba(255, 215, 0, 0.7);
}

.podium-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.podium-overlay.visible {
  opacity: 1;
}
h2 {
  color: white;
}

.podium-container {
  position: relative;
  background: linear-gradient(135deg, rgba(30, 30, 40, 0.9), rgba(50, 50, 70, 0.9));
  padding: 3rem;
  border-radius: 20px;
  width: 90%;
  max-width: 1000px;
  box-shadow: 0 0 30px rgba(93, 61, 253, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.particle {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  opacity: 0.7;
  animation: float 5s infinite ease-in-out;
}

@keyframes float {
  0%, 100% { transform: translateY(0) translateX(0); }
  50% { transform: translateY(-50px) translateX(10px); }
}

.podium {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 2rem;
  margin: 3rem 0;
  position: relative;
  z-index: 2;
}

.podium-place {
  width: 220px;
  padding: 2rem 1.5rem;
  border-radius: 15px 15px 0 0;
  position: relative;
  transition: transform 0.3s ease;
}

.podium-place:hover {
  transform: translateY(-10px);
}

.podium-1 {
  height: 300px;
  background: var(--gold);
  box-shadow: var(--neon-glow);
}

.podium-2 {
  height: 240px;
  background: var(--silver);
}

.podium-3 {
  height: 200px;
  background: var(--bronze);
}

.podium-rank {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.trophy-icon {
  color: gold;
  filter: drop-shadow(0 0 5px rgba(255, 215, 0, 0.7));
  font-size: 2rem;
}

.medal-icon {
  color: silver;
  filter: drop-shadow(0 0 5px rgba(192, 192, 192, 0.7));
  font-size: 2rem;
}

.star-icon {
  color: #ff9d00;
  filter: drop-shadow(0 0 5px rgba(255, 157, 0, 0.7));
  font-size: 2rem;
}

.podium-name {
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
  margin: 1.5rem 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.podium-time {
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
}

.player-email {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 0.5rem;
}

.restart-button {
  background: linear-gradient(to right, #5d3dfd, #9036d5);
  color: white;
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.restart-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(93, 61, 253, 0.7);
}

.restart-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.restart-button:hover::after {
  left: 100%;
}

@media (max-width: 768px) {
  .podium {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
  
  .podium-place {
    width: 180px;
    height: auto !important;
  }
}