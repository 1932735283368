.sidebar {
  width: 320px;
  min-width: 320px;
  background: var(--sidebar-bg);
  color: var(--sidebar-text);
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;
  transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  border-right: 1px solid var(--border-color);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  box-shadow: 8px 0 24px rgba(0, 0, 0, 0.2);
}

.sidebar-content {
  padding: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.cancel-session-button {
  width: 100%;
  padding: 14px 0;
  background: transparent;
  color: var(--button-text, var(--sidebar-text));
  border: 1px solid var(--border-color);
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.95em;
  font-weight: 500;
  letter-spacing: 0.3px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.cancel-session-button:hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: var(--accent-color);
}

.cancel-session-button .icon {
  font-size: 1.1em;
  opacity: 0.8;
}

/* Animación de entrada */
.sidebar.active {
  transform: translateX(0);
  animation: sidebarEntrance 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes sidebarEntrance {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Responsive */
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: -320px;
    top: 0;
    height: 100vh;
  }

  .sidebar.active {
    left: 0;
  }

  .sidebar-content {
    padding: 28px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 300px;
  }

  .sidebar-content {
    padding: 24px;
    gap: 24px;
  }
}