.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* index.css */

:root {
  /* Brand Colors */
  --white: #fcfcfc;
  --blue: #5d3dfd;
  --purple: #9036d5;
  --pink-purple: #c42fad;
  --bright-pink: #f82885;

  /* Customization Variables (default values) */
  --navbar-bg: linear-gradient(135deg, var(--blue), var(--purple));
  --sidebar-bg: linear-gradient(180deg, var(--purple), var(--pink-purple));
  --navbar-title-color: var(--white);
  --sidebar-text-color: var(--white);
  --hover-bg: var(--bright-pink);
  --hover-text-color: var(--white);
}

/* Cropper Modal Styles */
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ReactModal__Content {
  position: relative;
  background: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  border: none !important;
}

.cropper-container {
  margin: 1rem 0;
}

.btn-save {
  background: #6C5CE7;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.btn-save:hover {
  background: #5a4bd1;
}

.btn-save:disabled {
  background: #cccccc;
  cursor: not-allowed;
}