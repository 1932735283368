/* MemoryMatchGame.module.css */

/* Variables de diseño */
:root {
  --white: #fcfcfc;
  --blue: #5d3dfd;
  --purple: #9036d5;
  --pink-purple: #c42fad;
  --bright-pink: #f82885;
  --dark-overlay: rgba(0, 0, 0, 0.85);
  --gold: #ffd700;
  --silver: #c0c0c0;
  --bronze: #cd7f32;
  --shadow-color: rgba(0, 0, 0, 0.3);
  --font-family: "Montserrat", sans-serif;
  --button-hover: var(--pink-purple);
  --input-border: #ccc;
  --input-focus: var(--blue);
  --card-border-radius: 8px;
  --card-border-width: 2px;
}

/* Importar fuente */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

/* Reset y estilos base */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.memoryMatchGame {
  font-family: var(--font-family);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow: hidden;
}

.gameLayout {
  display: flex;
  flex: 1;
  min-height: 0;
}

/* Contenedor principal del juego - Ocupa todo el espacio */
.gameBoardContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: var(--gameboard-background-color, var(--white));
  position: relative;
  overflow: hidden;
}

/* Tablero de juego - Grid 6x4 ajustable */
.gameBoard {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  max-height: 800px;
  perspective: 1000px;
  padding: 5px;
}

/* Tarjetas - Perfectamente cuadradas */
.card {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
  cursor: pointer;
}

.cardInner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
  border-radius: var(--card-border-radius);
  box-shadow: 0 3px 6px var(--shadow-color);
}

.cardFront, 
.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: var(--card-border-radius);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardFront {
  background-color: white;
  transform: rotateY(180deg);
  border: var(--card-border-width) solid var(--card-border-color, var(--blue));
  box-sizing: border-box;
}

.cardBack {
  background-color: var(--sidebar-bg, var(--purple));
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

/* Estados de las tarjetas */
.flipped .cardInner {
  transform: rotateY(180deg);
}

.matched .cardFront {
  box-shadow: 0 0 15px var(--gold);
  position: relative;
  overflow: hidden;
}

.matched .cardFront::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
  transform: rotate(30deg);
  animation: shine 2s infinite;
}

@keyframes shine {
  0% { transform: translateX(-100%) rotate(30deg); }
  100% { transform: translateX(100%) rotate(30deg); }
}

/* Pantallas de inicio/fin */
.startContainer, 
.gameFinished {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--dark-overlay);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.playerInput {
  width: 80%;
  max-width: 400px;
  padding: 12px 20px;
  margin: 10px 0;
  border: 2px solid var(--input-border);
  border-radius: 25px;
  font-size: 1.1rem;
  background: rgba(255, 255, 255, 0.95);
  transition: all 0.2s ease;
}

.playerInput:focus {
  outline: none;
  border-color: var(--input-focus);
  box-shadow: 0 0 8px var(--input-focus);
}

.startButton {
  background: linear-gradient(45deg, var(--blue), var(--purple));
  color: white;
  border: none;
  padding: 12px 25px;
  margin-top: 15px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 12px var(--shadow-color);
  transition: all 0.2s ease;
}

.startButton:hover {
  background: linear-gradient(45deg, var(--purple), var(--pink-purple));
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.startButton:active {
  transform: translateY(0);
}

.gameFinished h2 {
  color: var(--bright-pink);
  font-size: 2.2rem;
  margin-bottom: 12px;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

.gameFinished p {
  color: white;
  font-size: 1.6rem;
  margin-bottom: 25px;
}

/* Vista previa */
.previewOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.previewCountdown {
  color: white;
  font-size: 5rem;
  font-weight: bold;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.8; }
  100% { transform: scale(1); opacity: 1; }
}

/* Screensaver */
.screensaverOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 1000;
  cursor: pointer;
}

.screensaverOverlay::after {
  content: 'Toca para continuar';
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.3rem;
  background: rgba(0, 0, 0, 0.6);
  padding: 8px 20px;
  border-radius: 25px;
  animation: bounce 2s infinite;
}

/* Ajustes responsivos (solo tamaño de tarjetas) */
@media (max-height: 800px) {
  .gameBoard {
    gap: 8px;
    max-height: 700px;
  }
  
  .cardBack {
    font-size: 1.8rem;
  }
}

@media (max-height: 700px) {
  .gameBoard {
    gap: 6px;
    max-height: 600px;
  }
  
  .cardBack {
    font-size: 1.6rem;
  }
}

@media (max-height: 600px) {
  .gameBoard {
    gap: 5px;
    max-height: 500px;
  }
  
  .cardBack {
    font-size: 1.4rem;
  }
}

@media (max-width: 1000px) {
  .gameBoard {
    gap: 8px;
    max-width: 1000px;
  }
}

@media (max-width: 800px) {
  .gameBoard {
    gap: 6px;
  }
  
  .cardBack {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  .gameBoard {
    gap: 5px;
  }
  
  .cardBack {
    font-size: 1.2rem;
  }
}