/* src/components/GameConfig/GameConfig.css */
:root {
  --primary-color: #6C5CE7;
  --secondary-color: #A29BFE;
  --accent-color: #FD79A8;
  --dark-color: #2D3436;
  --light-color: #F5F6FA;
  --success-color: #00B894;
  --warning-color: #FDCB6E;
  --danger-color: #D63031;
  --border-radius: 12px;
  --box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  --transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}


.game-config-container {
  
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: var(--dark-color);
  background-color: #ecebeb;
}

.config-header {
  text-align: center;
  margin-bottom: 3rem;
}

.config-header h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.config-header p {
  font-size: 1.1rem;
  color: #666;
}

.config-layout {
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: 2rem;
  min-height: 600px;
}

.config-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.nav-btn {
  padding: 1rem 1.5rem;
  border: none;
  background: none;
  text-align: left;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: var(--transition);
}

.nav-btn:hover {
  background: rgba(108, 92, 231, 0.1);
  color: var(--primary-color);
}

.nav-btn.active {
  background: var(--primary-color);
  color: white;
}

.nav-btn svg {
  font-size: 1.2rem;
}

.config-main {
  background: white;
  border-radius: var(--border-radius);
  padding: 2rem;
  box-shadow: var(--box-shadow);
}

.game-name-section {
  margin-bottom: 2rem;
}

.game-name-section label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--dark-color);
}

.game-name-section input {
  width: 100%;
  padding: 1rem;
  border: 2px solid #eee;
  border-radius: var(--border-radius);
  font-size: 1.1rem;
  transition: var(--transition);
}

.game-name-section input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(108, 92, 231, 0.2);
}

.tab-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.design-tab {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.logo-customization,
.color-customization,
.live-preview-section,
.time-settings {
  background: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.logo-customization h2,
.color-customization h2,
.live-preview-section h2,
.time-settings h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--dark-color);
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-weight: 500;
  margin-bottom: 0.75rem;
  color: var(--dark-color);
}

.form-group input[type="text"],
.form-group input[type="number"] {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #eee;
  border-radius: var(--border-radius);
  font-size: 1rem;
  transition: var(--transition);
}

.form-group input[type="text"]:focus,
.form-group input[type="number"]:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(108, 92, 231, 0.2);
}

.logo-options {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  border: 2px solid #eee;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: var(--transition);
}

.radio-label:hover {
  border-color: var(--primary-color);
  background: rgba(108, 92, 231, 0.05);
}

.radio-label input[type="radio"] {
  margin: 0;
  accent-color: var(--primary-color);
}

.logo-upload {
  margin-top: 1rem;
}

.logo-upload input[type="file"] {
  display: none;
}

.upload-btn {
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: var(--transition);
}

.upload-btn:hover {
  background: #5a4bd1;
  transform: translateY(-2px);
}

.logo-preview {
  margin-top: 1rem;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(214, 48, 49, 0.9);
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
}

.delete-btn:hover {
  background: #bb2d3b;
}

.color-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
}

.color-option {
  background: var(--light-color);
  padding: 1.5rem;
  border-radius: var(--border-radius);
}

.color-option label {
  display: block;
  margin-bottom: 1rem;
  font-weight: 500;
}

.color-picker {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.color-picker input[type="color"] {
  width: 50px;
  height: 50px;
  border: 2px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
}

.color-picker span {
  font-family: monospace;
  font-size: 0.9rem;
}

.live-preview-section {
  margin-top: 2rem;
}

.preview-container {
  border: 1px solid #eee;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.game-layout {
  display: flex;
  min-height: 400px;
}

.gameboard-preview {
  flex: 1;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards-preview {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 400px;
}

.card-preview {
  width: 80px;
  height: 120px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: var(--transition);
}

.puzzle-preview {
  width: 100%;
  height: 300px;
  background-color: #eee;
  border-radius: 8px;
}

.settings-tab {
  background: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.setting-option {
  margin-bottom: 1.5rem;
}

.setting-option label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.setting-option input[type="number"] {
  width: 80px;
  padding: 0.75rem;
  border: 2px solid #eee;
  border-radius: var(--border-radius);
  text-align: center;
  font-size: 1rem;
}

.setting-description {
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.5rem;
}

.config-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.save-btn {
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: var(--transition);
  box-shadow: 0 4px 12px rgba(108, 92, 231, 0.3);
}

.save-btn:hover {
  background: #5a4bd1;
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(108, 92, 231, 0.4);
}

.save-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

@media (max-width: 768px) {
  .config-layout {
    grid-template-columns: 1fr;
  }
  
  .color-grid {
    grid-template-columns: 1fr 1fr;
  }
  
  .cards-preview {
    grid-template-columns: repeat(2, 1fr);
  }
}