/* GameNavbar.css - Versión Atractiva */

.game-navbar {
  width: 100%;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--navbar-bg, #5d3dfd);
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 100;
}

/* Logo - Solo muestra si hay imagen */
.navbar-logo {
  position: absolute;
  left: 24px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
}

.navbar-logo .logo {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

/* Título atractivo */
.navbar-title {
  font-size: 1.8em;
  font-weight: 800;
  text-align: center;
  color: var(--navbar-title-text-color, #ffffff);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 
    0 2px 4px rgba(0, 0, 0, 0.3),
    0 0 10px rgba(255, 255, 255, 0.2);
  padding: 8px 24px;
  position: relative;
  display: inline-block;
}

.navbar-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 3px;
  background: var(--navbar-title-text-color, #ffffff);
  opacity: 0.7;
  border-radius: 3px;
  animation: pulseUnderline 2s infinite;
}

@keyframes pulseUnderline {
  0%, 100% { width: 80%; opacity: 0.7; }
  50% { width: 90%; opacity: 0.9; }
}

/* Menú */
.navbar-menu {
  position: absolute;
  right: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.1);
}

.navbar-menu:hover {
  background: rgba(255, 255, 255, 0.2);

}

.navbar-menu svg {
  font-size: 1.4em;
  color: var(--navbar-title-text-color, #ffffff);
}

/* Dropdown */
.dropdown-menu {
  position: absolute;
  top: 65px;
  right: 0;
  background: rgba(255, 255, 255, 0.98);
  border-radius: 10px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  width: 200px;
  z-index: 200;
  overflow: hidden;
  animation: fadeIn 0.25s ease-out;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: 14px 20px;
  color: #333;
  font-weight: 600;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background: var(--navbar-bg, #5d3dfd);
  color: white;
  padding-left: 24px;
}

.dropdown-item:hover::before {
  content: '→';
  margin-right: -4px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive */
@media (max-width: 768px) {
  .game-navbar {
    padding: 10px 16px;
  }
  
  .navbar-title {
    font-size: 1.5em;
    padding: 6px 16px;
  }
  
  .navbar-logo {
    left: 16px;
    height: 42px;
    width: 42px;
  }
  
  .navbar-menu {
    right: 16px;
    width: 40px;
    height: 40px;
  }
  
  .dropdown-menu {
    width: 180px;
  }
}

@media (max-width: 480px) {
  .navbar-title {
    font-size: 1.3em;
    letter-spacing: 1px;
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .navbar-title::after {
    width: 70%;
  }
  
  @keyframes pulseUnderline {
    0%, 100% { width: 70%; }
    50% { width: 80%; }
  }
}