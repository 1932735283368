.scores-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scores-modal-content {
  background: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.scores-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #5d3dfd;
  color: #fff;
  padding: 15px;
}

.scores-modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.close-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1.2rem;
  transition: transform 0.2s;
}

.close-button:hover {
  transform: scale(1.1);
}

.scores-modal-body {
  padding: 20px;
  flex: 1;
  overflow-y: auto;
}

.scores-table {
  width: 100%;
  border-collapse: collapse;
}

.scores-table th,
.scores-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.scores-table th {
  background-color: #f2f2f2;
}

.btn-delete-score {
  background-color: #f82885;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.btn-delete-score:hover {
  background-color: #c42fad;
}

.scores-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background-color: #f2f2f2;
}

.btn-delete-all {
  background-color: #f82885;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.btn-delete-all:hover {
  background-color: #c42fad;
}
