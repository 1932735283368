.event-detail {
  background: #f7f8fc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.event-detail-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.games-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
}

.game-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  flex: 1 1 300px;
  max-width: 300px;
}

.game-card h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.game-logo {
  width: 100px;
  height: auto;
  margin-bottom: 15px;
}

.game-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

/* Botones */
.btn-primary {
  background-color: #5d3dfd;
  color: #fff;
}
.btn-primary:hover {
  background-color: #482ccc;
}

.btn-secondary {
  background-color: #9036d5;
  color: #fff;
}
.btn-secondary:hover {
  background-color: #6e28a7;
}

.btn-danger {
  background-color: #f82885;
  color: #fff;
}
.btn-danger:hover {
  background-color: #c42fad;
}

.btn-info {
  background-color: #10b981; /* Un verde turquesa */
  color: #fff;
}
.btn-info:hover {
  background-color: #059669;
}

.btn-add {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  background-color: #10b981;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin: 20px auto;
  transition: background-color 0.3s ease;
}
.btn-add:hover {
  background-color: #059669;
}
